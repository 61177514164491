.Logo {
    background-color: white;
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    color: #0d1a47;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.5;
}

.Logo img {
    height: 100%;
}