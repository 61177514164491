.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 1rem;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    margin-bottom: 5px;
    border-bottom: 1px solid #c1c4c6;
}

.Logo img {
    max-width: 100%;
    height: 70%;
}

.NavWrapper {
    margin-top: 15px;
}

@media (min-width: 500px) {
    .NavWrapper {
        margin-top: 0;
    }

    .SideDrawer {
        display: none;
    }
}