.Button {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 0;
    font-weight: bold;
}

/*.Button:not(.Button-link):first-of-type {*/
/*    margin-left: 0;*/
/*    !* padding-left: 0; *!*/
/*}*/

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}

.Button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .Button {
        transition: none;
    }
}

.Button:hover {
    color: #212529;
    text-decoration: none;
}

.Button:focus, .Button.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.Button.disabled, .Button:disabled {
    opacity: 0.65;
}

.Button:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.Button.disabled,
fieldset:disabled a.Button {
    pointer-events: none;
}

.Button-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.Button-primary:focus, .Button-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.Button-primary.disabled, .Button-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button-primary:not(:disabled):not(.disabled):active, .Button-primary:not(:disabled):not(.disabled).active,
.show > .Button-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.Button-primary:not(:disabled):not(.disabled):active:focus, .Button-primary:not(:disabled):not(.disabled).active:focus,
.show > .Button-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.Button-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.Button-secondary:focus, .Button-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.Button-secondary.disabled, .Button-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button-secondary:not(:disabled):not(.disabled):active, .Button-secondary:not(:disabled):not(.disabled).active,
.show > .Button-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.Button-secondary:not(:disabled):not(.disabled):active:focus, .Button-secondary:not(:disabled):not(.disabled).active:focus,
.show > .Button-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.Button.Success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button.Success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.Button.Success:focus, .Button.Success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.Button.Success.disabled, .Button.Success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button.Success:not(:disabled):not(.disabled):active, .Button.Success:not(:disabled):not(.disabled).active,
.show > .Button.Success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}

.Button.Success:not(:disabled):not(.disabled):active:focus, .Button.Success:not(:disabled):not(.disabled).active:focus,
.show > .Button.Success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.Button-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.Button-info:focus, .Button-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.Button-info.disabled, .Button-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button-info:not(:disabled):not(.disabled):active, .Button-info:not(:disabled):not(.disabled).active,
.show > .Button-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.Button-info:not(:disabled):not(.disabled):active:focus, .Button-info:not(:disabled):not(.disabled).active:focus,
.show > .Button-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.Button-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.Button-warning:focus, .Button-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.Button-warning.disabled, .Button-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button-warning:not(:disabled):not(.disabled):active, .Button-warning:not(:disabled):not(.disabled).active,
.show > .Button-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

.Button-warning:not(:disabled):not(.disabled):active:focus, .Button-warning:not(:disabled):not(.disabled).active:focus,
.show > .Button-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.Button.Danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button.Danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.Button.Danger:focus, .Button.Danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.Button.Danger.disabled, .Button.Danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button.Danger:not(:disabled):not(.disabled):active, .Button.Danger:not(:disabled):not(.disabled).active,
.show > .Button.Danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.Button.Danger:not(:disabled):not(.disabled):active:focus, .Button.Danger:not(:disabled):not(.disabled).active:focus,
.show > .Button.Danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.Button-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.Button-light:focus, .Button-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.Button-light.disabled, .Button-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button-light:not(:disabled):not(.disabled):active, .Button-light:not(:disabled):not(.disabled).active,
.show > .Button-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.Button-light:not(:disabled):not(.disabled):active:focus, .Button-light:not(:disabled):not(.disabled).active:focus,
.show > .Button-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.Button-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.Button-dark:focus, .Button-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.Button-dark.disabled, .Button-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button-dark:not(:disabled):not(.disabled):active, .Button-dark:not(:disabled):not(.disabled).active,
.show > .Button-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.Button-dark:not(:disabled):not(.disabled):active:focus, .Button-dark:not(:disabled):not(.disabled).active:focus,
.show > .Button-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.Button-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

.Button-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button-outline-primary:focus, .Button-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.Button-outline-primary.disabled, .Button-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}

.Button-outline-primary:not(:disabled):not(.disabled):active, .Button-outline-primary:not(:disabled):not(.disabled).active,
.show > .Button-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button-outline-primary:not(:disabled):not(.disabled):active:focus, .Button-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.Button-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.Button-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button-outline-secondary:focus, .Button-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.Button-outline-secondary.disabled, .Button-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

.Button-outline-secondary:not(:disabled):not(.disabled):active, .Button-outline-secondary:not(:disabled):not(.disabled).active,
.show > .Button-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button-outline-secondary:not(:disabled):not(.disabled):active:focus, .Button-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.Button-outline-success {
    color: #28a745;
    border-color: #28a745;
}

.Button-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button-outline-success:focus, .Button-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.Button-outline-success.disabled, .Button-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
}

.Button-outline-success:not(:disabled):not(.disabled):active, .Button-outline-success:not(:disabled):not(.disabled).active,
.show > .Button-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button-outline-success:not(:disabled):not(.disabled):active:focus, .Button-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.Button-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.Button-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button-outline-info:focus, .Button-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.Button-outline-info.disabled, .Button-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.Button-outline-info:not(:disabled):not(.disabled):active, .Button-outline-info:not(:disabled):not(.disabled).active,
.show > .Button-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button-outline-info:not(:disabled):not(.disabled):active:focus, .Button-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.Button-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
}

.Button-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button-outline-warning:focus, .Button-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.Button-outline-warning.disabled, .Button-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
}

.Button-outline-warning:not(:disabled):not(.disabled):active, .Button-outline-warning:not(:disabled):not(.disabled).active,
.show > .Button-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button-outline-warning:not(:disabled):not(.disabled):active:focus, .Button-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.Button-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.Button-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button-outline-danger:focus, .Button-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.Button-outline-danger.disabled, .Button-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
}

.Button-outline-danger:not(:disabled):not(.disabled):active, .Button-outline-danger:not(:disabled):not(.disabled).active,
.show > .Button-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button-outline-danger:not(:disabled):not(.disabled):active:focus, .Button-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.Button-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button-outline-light:focus, .Button-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.Button-outline-light.disabled, .Button-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.Button-outline-light:not(:disabled):not(.disabled):active, .Button-outline-light:not(:disabled):not(.disabled).active,
.show > .Button-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button-outline-light:not(:disabled):not(.disabled):active:focus, .Button-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.Button-outline-dark {
    color: #343a40;
    border-color: #343a40;
}

.Button-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button-outline-dark:focus, .Button-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.Button-outline-dark.disabled, .Button-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.Button-outline-dark:not(:disabled):not(.disabled):active, .Button-outline-dark:not(:disabled):not(.disabled).active,
.show > .Button-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button-outline-dark:not(:disabled):not(.disabled):active:focus, .Button-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .Button-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.Button-link {
    font-weight: 400;
    color: #007bff;
}

.Button-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.Button-link:focus, .Button-link.focus {
    text-decoration: underline;
    box-shadow: none;
}

.Button-link:disabled, .Button-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.Button-lg, .Button-group-lg > .Button {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.Button-sm, .Button-group-sm > .Button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.Button-block {
    display: block;
    width: 100%;
}

.Button-block + .Button-block {
    margin-top: 0.5rem;
}

input[type="submit"].Button-block,
input[type="reset"].Button-block,
input[type="button"].Button-block {
    width: 100%;
}
  