.UpperCase {
  text-transform: uppercase !important;
}

.FlightData {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  /* box-shadow: 0 2px 3px #ccc; */
  /* border: 1px solid #eee; */
  padding: 10px;
  box-sizing: border-box;
}

.Title {
  font-size: 1.5em;
  margin-bottom: 2.5rem;
}

@media (min-width: 600px) {
  .FlightData {
    width: 500px;
  }
  .Title {
    font-size: 2em;
  }
}
