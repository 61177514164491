body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  touch-action: manipulation; /* diasble double tap to zoom on iOS */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Layout_Content__3WkOt {
    margin-top: 56px;
}
.Toolbar_Toolbar__36oSa {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0d1a47;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar_Toolbar__36oSa nav {
    height: 100%;
}

.Toolbar_Logo__1Dp4Y {
    height: 80%;
}

@media (max-width: 499px) {
    .Toolbar_DesktopOnly__3nMx2 {
        display: none;
    }
}
.Logo_Logo__3w68r {
    background-color: white;
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    color: #0d1a47;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.5;
}

.Logo_Logo__3w68r img {
    height: 100%;
}
.NavigationItems_NavigationItems__3GYHd {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__3GYHd {
        flex-flow: row;
    }
}
.NavigationItem_NavigationItem__1JYfo {
    font-size: 1.25rem;
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem_NavigationItem__1JYfo a {
    color: #323c41;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    font-weight: bold;
}

.NavigationItem_NavigationItem__1JYfo a:hover,
.NavigationItem_NavigationItem__1JYfo a:active,
.NavigationItem_NavigationItem__1JYfo a.NavigationItem_active__3OWkJ {
    color: #0d1a47;
}

@media (min-width: 500px) {
    .NavigationItem_NavigationItem__1JYfo {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem_NavigationItem__1JYfo a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem_NavigationItem__1JYfo a:hover,
    .NavigationItem_NavigationItem__1JYfo a:active,
    .NavigationItem_NavigationItem__1JYfo a.NavigationItem_active__3OWkJ {
        background-color: #ffffff;
        border-bottom: 4px solid #eee;
        color: #0d1a47;
    }
}
.DrawerToggle_DrawerToggle__3ioiW {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_DrawerToggle__3ioiW div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle_DrawerToggle__3ioiW {
        display: none;
    }
}
.SideDrawer_SideDrawer__Zf1dl {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 1rem;
    box-sizing: border-box;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer_SideDrawer__Zf1dl {
        display: none;
    }
}

.SideDrawer_Open__1WDq5 {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.SideDrawer_Close__2ZxK7 {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.SideDrawer_Logo__mRS1q {
    margin-bottom: 5px;
    border-bottom: 1px solid #c1c4c6;
}

.SideDrawer_Logo__mRS1q img {
    max-width: 100%;
    height: 70%;
}

.SideDrawer_NavWrapper__378qI {
    margin-top: 15px;
}

@media (min-width: 500px) {
    .SideDrawer_NavWrapper__378qI {
        margin-top: 0;
    }

    .SideDrawer_SideDrawer__Zf1dl {
        display: none;
    }
}
.Backdrop_Backdrop__1Fvww {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.Input_Input__182S3 {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
}

.Input_Label__2UF3_ {
  font-weight: bold;
  text-align: left;
  display: block;
  margin-bottom: 8px;
}

.Input_InputElement__1LlFC {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  font: inherit;
  padding: 15px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.Input_InputElement__1LlFC:focus {
  outline: none;
  background-color: #ccc;
}

.Input_UpperCase__P6FYy {
  text-transform: uppercase !important;
}

.Input_Invalid__3mq7G {
  border: 1px solid red;
  background-color: #fda49a;
}

.Button_Button__1kunK {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 0;
    font-weight: bold;
}

/*.Button:not(.Button-link):first-of-type {*/
/*    margin-left: 0;*/
/*    !* padding-left: 0; *!*/
/*}*/

.Button_Button__1kunK:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Button_Success__3zxDs {
    color: #5C9210;
}

.Button_Danger__A6k5Z {
    color: #944317;
}

.Button_Button__1kunK {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .Button_Button__1kunK {
        transition: none;
    }
}

.Button_Button__1kunK:hover {
    color: #212529;
    text-decoration: none;
}

.Button_Button__1kunK:focus, .Button_Button__1kunK.Button_focus__3DRxt {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.Button_Button__1kunK.Button_disabled__vLdyU, .Button_Button__1kunK:disabled {
    opacity: 0.65;
}

.Button_Button__1kunK:not(:disabled):not(.Button_disabled__vLdyU) {
    cursor: pointer;
}

a.Button_Button__1kunK.Button_disabled__vLdyU,
fieldset:disabled a.Button_Button__1kunK {
    pointer-events: none;
}

.Button_Button-primary__22u-c {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button_Button-primary__22u-c:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.Button_Button-primary__22u-c:focus, .Button_Button-primary__22u-c.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.Button_Button-primary__22u-c.Button_disabled__vLdyU, .Button_Button-primary__22u-c:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button_Button-primary__22u-c:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-primary__22u-c:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-primary__22u-c.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.Button_Button-primary__22u-c:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-primary__22u-c:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-primary__22u-c.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.Button_Button-secondary__3ms7C {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button_Button-secondary__3ms7C:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.Button_Button-secondary__3ms7C:focus, .Button_Button-secondary__3ms7C.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.Button_Button-secondary__3ms7C.Button_disabled__vLdyU, .Button_Button-secondary__3ms7C:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button_Button-secondary__3ms7C:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-secondary__3ms7C:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-secondary__3ms7C.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.Button_Button-secondary__3ms7C:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-secondary__3ms7C:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-secondary__3ms7C.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.Button_Button__1kunK.Button_Success__3zxDs {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button_Button__1kunK.Button_Success__3zxDs:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.Button_Button__1kunK.Button_Success__3zxDs:focus, .Button_Button__1kunK.Button_Success__3zxDs.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.Button_Button__1kunK.Button_Success__3zxDs.Button_disabled__vLdyU, .Button_Button__1kunK.Button_Success__3zxDs:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button_Button__1kunK.Button_Success__3zxDs:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button__1kunK.Button_Success__3zxDs:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button__1kunK.Button_Success__3zxDs.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}

.Button_Button__1kunK.Button_Success__3zxDs:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button__1kunK.Button_Success__3zxDs:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button__1kunK.Button_Success__3zxDs.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.Button_Button-info__3OyZw {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button_Button-info__3OyZw:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.Button_Button-info__3OyZw:focus, .Button_Button-info__3OyZw.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.Button_Button-info__3OyZw.Button_disabled__vLdyU, .Button_Button-info__3OyZw:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button_Button-info__3OyZw:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-info__3OyZw:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-info__3OyZw.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.Button_Button-info__3OyZw:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-info__3OyZw:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-info__3OyZw.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.Button_Button-warning__1Odwq {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button_Button-warning__1Odwq:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.Button_Button-warning__1Odwq:focus, .Button_Button-warning__1Odwq.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.Button_Button-warning__1Odwq.Button_disabled__vLdyU, .Button_Button-warning__1Odwq:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button_Button-warning__1Odwq:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-warning__1Odwq:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-warning__1Odwq.Button_dropdown-toggle__3ngu1 {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

.Button_Button-warning__1Odwq:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-warning__1Odwq:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-warning__1Odwq.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.Button_Button__1kunK.Button_Danger__A6k5Z {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button_Button__1kunK.Button_Danger__A6k5Z:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.Button_Button__1kunK.Button_Danger__A6k5Z:focus, .Button_Button__1kunK.Button_Danger__A6k5Z.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.Button_Button__1kunK.Button_Danger__A6k5Z.Button_disabled__vLdyU, .Button_Button__1kunK.Button_Danger__A6k5Z:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button_Button__1kunK.Button_Danger__A6k5Z:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button__1kunK.Button_Danger__A6k5Z:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button__1kunK.Button_Danger__A6k5Z.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.Button_Button__1kunK.Button_Danger__A6k5Z:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button__1kunK.Button_Danger__A6k5Z:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button__1kunK.Button_Danger__A6k5Z.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.Button_Button-light__2_LLm {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button_Button-light__2_LLm:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.Button_Button-light__2_LLm:focus, .Button_Button-light__2_LLm.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.Button_Button-light__2_LLm.Button_disabled__vLdyU, .Button_Button-light__2_LLm:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button_Button-light__2_LLm:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-light__2_LLm:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-light__2_LLm.Button_dropdown-toggle__3ngu1 {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.Button_Button-light__2_LLm:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-light__2_LLm:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-light__2_LLm.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.Button_Button-dark__1vtkk {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button_Button-dark__1vtkk:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.Button_Button-dark__1vtkk:focus, .Button_Button-dark__1vtkk.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.Button_Button-dark__1vtkk.Button_disabled__vLdyU, .Button_Button-dark__1vtkk:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button_Button-dark__1vtkk:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-dark__1vtkk:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-dark__1vtkk.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.Button_Button-dark__1vtkk:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-dark__1vtkk:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-dark__1vtkk.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.Button_Button-outline-primary__2xCjt {
    color: #007bff;
    border-color: #007bff;
}

.Button_Button-outline-primary__2xCjt:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button_Button-outline-primary__2xCjt:focus, .Button_Button-outline-primary__2xCjt.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.Button_Button-outline-primary__2xCjt.Button_disabled__vLdyU, .Button_Button-outline-primary__2xCjt:disabled {
    color: #007bff;
    background-color: transparent;
}

.Button_Button-outline-primary__2xCjt:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-primary__2xCjt:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-primary__2xCjt.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.Button_Button-outline-primary__2xCjt:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-primary__2xCjt:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-primary__2xCjt.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.Button_Button-outline-secondary__1LD0k {
    color: #6c757d;
    border-color: #6c757d;
}

.Button_Button-outline-secondary__1LD0k:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button_Button-outline-secondary__1LD0k:focus, .Button_Button-outline-secondary__1LD0k.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.Button_Button-outline-secondary__1LD0k.Button_disabled__vLdyU, .Button_Button-outline-secondary__1LD0k:disabled {
    color: #6c757d;
    background-color: transparent;
}

.Button_Button-outline-secondary__1LD0k:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-secondary__1LD0k:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-secondary__1LD0k.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.Button_Button-outline-secondary__1LD0k:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-secondary__1LD0k:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-secondary__1LD0k.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.Button_Button-outline-success__145uK {
    color: #28a745;
    border-color: #28a745;
}

.Button_Button-outline-success__145uK:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button_Button-outline-success__145uK:focus, .Button_Button-outline-success__145uK.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.Button_Button-outline-success__145uK.Button_disabled__vLdyU, .Button_Button-outline-success__145uK:disabled {
    color: #28a745;
    background-color: transparent;
}

.Button_Button-outline-success__145uK:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-success__145uK:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-success__145uK.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.Button_Button-outline-success__145uK:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-success__145uK:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-success__145uK.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.Button_Button-outline-info__NM3lr {
    color: #17a2b8;
    border-color: #17a2b8;
}

.Button_Button-outline-info__NM3lr:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button_Button-outline-info__NM3lr:focus, .Button_Button-outline-info__NM3lr.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.Button_Button-outline-info__NM3lr.Button_disabled__vLdyU, .Button_Button-outline-info__NM3lr:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.Button_Button-outline-info__NM3lr:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-info__NM3lr:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-info__NM3lr.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.Button_Button-outline-info__NM3lr:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-info__NM3lr:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-info__NM3lr.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.Button_Button-outline-warning__1hfC- {
    color: #ffc107;
    border-color: #ffc107;
}

.Button_Button-outline-warning__1hfC-:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button_Button-outline-warning__1hfC-:focus, .Button_Button-outline-warning__1hfC-.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.Button_Button-outline-warning__1hfC-.Button_disabled__vLdyU, .Button_Button-outline-warning__1hfC-:disabled {
    color: #ffc107;
    background-color: transparent;
}

.Button_Button-outline-warning__1hfC-:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-warning__1hfC-:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-warning__1hfC-.Button_dropdown-toggle__3ngu1 {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.Button_Button-outline-warning__1hfC-:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-warning__1hfC-:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-warning__1hfC-.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.Button_Button-outline-danger__2no-0 {
    color: #dc3545;
    border-color: #dc3545;
}

.Button_Button-outline-danger__2no-0:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button_Button-outline-danger__2no-0:focus, .Button_Button-outline-danger__2no-0.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.Button_Button-outline-danger__2no-0.Button_disabled__vLdyU, .Button_Button-outline-danger__2no-0:disabled {
    color: #dc3545;
    background-color: transparent;
}

.Button_Button-outline-danger__2no-0:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-danger__2no-0:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-danger__2no-0.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.Button_Button-outline-danger__2no-0:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-danger__2no-0:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-danger__2no-0.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.Button_Button-outline-light__39PdN {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button_Button-outline-light__39PdN:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button_Button-outline-light__39PdN:focus, .Button_Button-outline-light__39PdN.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.Button_Button-outline-light__39PdN.Button_disabled__vLdyU, .Button_Button-outline-light__39PdN:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.Button_Button-outline-light__39PdN:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-light__39PdN:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-light__39PdN.Button_dropdown-toggle__3ngu1 {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.Button_Button-outline-light__39PdN:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-light__39PdN:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-light__39PdN.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.Button_Button-outline-dark__LSoGg {
    color: #343a40;
    border-color: #343a40;
}

.Button_Button-outline-dark__LSoGg:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button_Button-outline-dark__LSoGg:focus, .Button_Button-outline-dark__LSoGg.Button_focus__3DRxt {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.Button_Button-outline-dark__LSoGg.Button_disabled__vLdyU, .Button_Button-outline-dark__LSoGg:disabled {
    color: #343a40;
    background-color: transparent;
}

.Button_Button-outline-dark__LSoGg:not(:disabled):not(.Button_disabled__vLdyU):active, .Button_Button-outline-dark__LSoGg:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5,
.Button_show__gARrW > .Button_Button-outline-dark__LSoGg.Button_dropdown-toggle__3ngu1 {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.Button_Button-outline-dark__LSoGg:not(:disabled):not(.Button_disabled__vLdyU):active:focus, .Button_Button-outline-dark__LSoGg:not(:disabled):not(.Button_disabled__vLdyU).Button_active__2-5P5:focus,
.Button_show__gARrW > .Button_Button-outline-dark__LSoGg.Button_dropdown-toggle__3ngu1:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.Button_Button-link__3kTvP {
    font-weight: 400;
    color: #007bff;
}

.Button_Button-link__3kTvP:hover {
    color: #0056b3;
    text-decoration: underline;
}

.Button_Button-link__3kTvP:focus, .Button_Button-link__3kTvP.Button_focus__3DRxt {
    text-decoration: underline;
    box-shadow: none;
}

.Button_Button-link__3kTvP:disabled, .Button_Button-link__3kTvP.Button_disabled__vLdyU {
    color: #6c757d;
    pointer-events: none;
}

.Button_Button-lg__2_dgk, .Button_Button-group-lg__1sT2j > .Button_Button__1kunK {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.Button_Button-sm__23FM9, .Button_Button-group-sm__1LNVe > .Button_Button__1kunK {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.Button_Button-block__3Qqus {
    display: block;
    width: 100%;
}

.Button_Button-block__3Qqus + .Button_Button-block__3Qqus {
    margin-top: 0.5rem;
}

input[type="submit"].Button_Button-block__3Qqus,
input[type="reset"].Button_Button-block__3Qqus,
input[type="button"].Button_Button-block__3Qqus {
    width: 100%;
}
  
.Flight_UpperCase__3mA4j {
  text-transform: uppercase !important;
}

.Flight_FlightData__1nd7u {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  /* box-shadow: 0 2px 3px #ccc; */
  /* border: 1px solid #eee; */
  padding: 10px;
  box-sizing: border-box;
}

.Flight_Title__17H_0 {
  font-size: 1.5em;
  margin-bottom: 2.5rem;
}

@media (min-width: 600px) {
  .Flight_FlightData__1nd7u {
    width: 500px;
  }
  .Flight_Title__17H_0 {
    font-size: 2em;
  }
}

.Modal_Modal__3Y61c {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal_Modal__3Y61c {
        width: 500px;
        left: calc(50% - 250px);
    }
}
